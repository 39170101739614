//colors
$gray-1: rgb(39, 39, 42);
$gray-2: rgb(24, 24, 27);
$gray-3: rgb(113, 113, 122);
$gray-4: rgb(82, 82, 91);
$gray-5: rgb(1, 1, 1);
$gray-6: rgb(30, 41, 59);
$gray-7: rgb(14, 17, 22);
$gray-8: rgb(34, 34, 37);
$gray-9: rgb(63, 63, 70);
$gray-10: rgb(161, 161, 170);
$gray-11: rgb(214, 211, 209);

$orange-1: rgb(249, 115, 22);
$orange-2: rgb(234, 88, 12);
$orange-3: rgb(251, 146, 60);
$orange-4: rgb(253, 186, 116);
$orange-5: rgb(255, 237, 213);
$orange-6: rgb(255, 247, 237);
$orange-7: rgba(204, 58, 10, 1);

$white: rgb(255, 255, 255);

$light-1: rgb(250, 250, 250);
$light-2: rgb(250, 250, 249);
$light-3: rgb(244, 244, 245);
$light-4: rgb(245, 245, 244);
$light-5: rgb(228, 228, 231);
$light-6: rgb(231, 229, 228);

$border-radius: 2px;

$max-width: 67rem;

//RWD
$mobile-extra-small-screen: 22.4em; //<360px
$mobile-small-screen: 23.6em; //385px
$mobile-medium-screen: 30em; //480px
$mobile-large-screen: 36em; //576px
$tablet-small-screen: 39em; //<640px
$tablet-medium-screen: 48em; //768px
$tablet-large-screen: 64em; //1024px
$desktop-screen: 75em; //1200px
$dekstop-screen-xl: 1300px;

//typography
@mixin header-1 {
  font-family: var(--sora-font);
  font-weight: 600;
  font-size: 50px;
  line-height: 100%;
  letter-spacing: -0.02rem;
}

@mixin header-2 {
  font-family: var(--sora-font);
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  letter-spacing: -0.03em;
}

@mixin header-3 {
  font-family: var(--sora-font);
  font-weight: 600;
  font-size: 40px;
  line-height: 110%;
  letter-spacing: -0.05em;
}

@mixin header-4 {
  font-family: var(--sora-font);
  font-weight: 600;
  font-size: 30px;
  line-height: 110%;
  letter-spacing: 0;
}

@mixin header-5 {
  font-family: var(--inter-font);
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

@mixin header-6 {
  font-family: var(--inter-font);
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
}

@mixin header-extra-small-mobile {
  font-family: var(--sora-font);
  font-weight: 600;
  font-size: 30px;
  line-height: 110%;
  letter-spacing: -0.05em;
}

@mixin special-header-2 {
  font-family: var(--sora-font);
  font-weight: 600;
  font-size: 30px;
  line-height: 110%;
  letter-spacing: 0;
}

@mixin price {
  font-family: Sora;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: center;
}

@mixin subheader-1 {
  font-family: var(--inter-font);
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0;
}

@mixin subheader-2 {
  font-family: var(--inter-font);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.01em;
}

@mixin subheader-3 {
  font-family: var(--inter-font);
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0;
}

@mixin subheader-4 {
  font-family: var(--inter-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0;
}

@mixin button-l {
  font-family: var(--inter-font);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  line-height: 120%;
  letter-spacing: 0;
}

@mixin button-m {
  font-family: var(--inter-font);
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  line-height: 120%;
  letter-spacing: 0;
}

@mixin button-s {
  font-family: var(--inter-font);
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  cursor: pointer;
  letter-spacing: 0;
}

@mixin body-1 {
  font-family: var(--inter-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0;
}

@mixin body-2 {
  font-family: var(--inter-font);
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0;
}

@mixin body-3 {
  font-family: var(--inter-font);
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  letter-spacing: 0;
}

@mixin quote-m {
  font-family: var(--inter-font);
  font-weight: 600;
  font-size: 30px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

@mixin quote-s {
  font-family: var(--inter-font);
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

@mixin caption-m {
  font-family: var(--inter-font);
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.05em;
}

@mixin caption-s {
  font-family: var(--inter-font);
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

@mixin caption-xs {
  font-family: var(--sora-font);
  font-weight: 600;
  font-size: 11px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

@mixin bullets {
  font-family: var(--inter-font);
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.01em;
}

@mixin bullets-s {
  font-family: var(--inter-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.01em;
}

@mixin menu {
  font-family: var(--sora-font);
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0;
}

@mixin special-header-1 {
  font-family: var(--sora-font);
  font-weight: 600;
  font-size: 46px;
  line-height: 110%;
  letter-spacing: -0.02em;
}

@mixin special-header-3 {
  font-family: var(--sora-font);
  font-weight: 600;
  font-size: 26px;
  line-height: 110%;
  letter-spacing: 0;
}

@mixin emoji-1 {
  font-family: Inter;
  font-size: 50px;
  font-weight: 400;
  line-height: 65px;
  letter-spacing: -0.01em;
  text-align: left;
}

@mixin emoji-2 {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.02em;
}

@mixin emoji-3 {
  font-family: Inter;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.02em;
}

@mixin brand {
  font-family: Sora;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

//diagonal container
@mixin diagonal-contaier($bg-color, $padding-bottom) {
  padding: 2.5rem 0 5rem;
  position: relative;
  isolation: isolate;
  width: 100%;
  display: flex;
  flex-direction: column;


  @media screen and (max-width: 48em) {
    padding: 2.5rem 0 5rem;
  }

  @media screen and (max-width: $tablet-small-screen) {
    padding: 2.5rem 0 $padding-bottom;
  }

}